import { useOrganisationServiceFeeQuery } from '@/generated-types/graphql.types';
import { withAuthQueryHook } from '@/util/hooks';
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { debounce, calculatePriceWithTax, calculatePriceWithTaxAndVAT } from '@/util/utils';
import { currencyFilter } from '@/util/templateFilters';
import i18n from '@/i18n/i18n-setup';
const useServiceFee = withAuthQueryHook(useOrganisationServiceFeeQuery);
var INPUT_TYPE;
(function (INPUT_TYPE) {
    INPUT_TYPE["PRICE"] = "price";
    INPUT_TYPE["ADVERTISED"] = "advertised";
    INPUT_TYPE["FINAL"] = "final";
})(INPUT_TYPE || (INPUT_TYPE = {}));
const generateAdvertisedPriceAppend = (value, serviceFee) => {
    return i18n.t('space.facilities.input_placeholder_on_demand_facility_append', {
        percent: serviceFee,
        price: calculatePriceWithTax(Number(value || 0), serviceFee)
    });
};
export default defineComponent({
    name: 'PriceInput',
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        error: {
            type: [String, Boolean],
            default: ''
        },
        max: {
            type: Number,
            default: 99999
        },
        advertised: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: [String, Number],
            default: 0
        },
        label: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        vat: {
            type: Number,
            default: null
        },
        service: {
            type: Boolean,
            default: false
        },
        withServiceFee: {
            type: Boolean,
            default: false
        },
        withVat: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const { result } = useServiceFee({}, {
            clientId: 'legacy'
        });
        const advertisedPrice = ref(null);
        const finalPrice = ref(null);
        const targetInput = ref('');
        const serviceFee = computed(() => result?.value?.viewerApiKey?.getApiOrganisation?.serviceFee);
        const VAT = computed(() => result?.value?.viewerApiKey?.getApiOrganisation?.vatRate);
        const vatRate = computed(() => {
            if (!props.service) {
                return `${VAT.value}% `;
            }
            else {
                return props.vat ? `${props.vat}% ` : '';
            }
        });
        const append = computed(() => generateAdvertisedPriceAppend(props.value || 0, serviceFee.value));
        const updateAdvertised = debounce(() => {
            advertisedPrice.value =
                Number(calculatePriceWithTax(props.value, serviceFee.value)) || null;
        }, 500);
        const updateFinal = debounce(() => {
            finalPrice.value =
                Number(calculatePriceWithTaxAndVAT(props.value, serviceFee.value, VAT.value)) || null;
        }, 500);
        onMounted(() => {
            updateAdvertised();
            updateFinal();
        });
        const onChange = (event) => {
            emit('input', event);
            if (targetInput.value === INPUT_TYPE.PRICE) {
                updateAdvertised();
                updateFinal();
            }
        };
        const onFocus = (event) => {
            const firstClassName = event.target.className.substr(0, event.target.className.indexOf(' '));
            targetInput.value = firstClassName || event.target.className;
        };
        const onChangeAdvertised = debounce(event => {
            if (targetInput.value === INPUT_TYPE.ADVERTISED) {
                const price = Number((event * 100) / ((serviceFee.value || 0) + 100));
                emit('input', price);
            }
        }, 500);
        const onChangeFinal = debounce(event => {
            if (targetInput.value === INPUT_TYPE.FINAL) {
                const price = Number(event /
                    ((VAT.value || 0) / 100 + 1) /
                    ((serviceFee.value || 0) / 100 + 1));
                emit('input', price);
            }
        }, 500);
        watch(() => props.value, () => {
            updateAdvertised();
            updateFinal();
            if (!props.value) {
                advertisedPrice.value = null;
                finalPrice.value = null;
            }
        }, { immediate: true });
        const computedPlaceholder = computed(() => {
            if (typeof props.placeholder === 'string')
                return props.placeholder;
            if (props.withServiceFee)
                return currencyFilter(props.placeholder * (1 + Number(serviceFee.value) / 100));
            return currencyFilter(props.placeholder);
        });
        const computedPlaceholderFinalPrice = computed(() => {
            if (typeof props.placeholder === 'string')
                return props.placeholder;
            if (props.withVat)
                return currencyFilter(props.placeholder *
                    (1 + Number(serviceFee.value) / 100) *
                    (1 + Number(VAT.value) / 100));
            return currencyFilter(props.placeholder);
        });
        return {
            append,
            advertisedPrice,
            computedPlaceholder,
            computedPlaceholderFinalPrice,
            finalPrice,
            onChangeAdvertised,
            onChangeFinal,
            serviceFee,
            vatRate,
            onChange,
            onFocus
        };
    }
});
